import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers} from 'formik';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  FormikCheckboxGroup,
  FormikDateInput,
  FormikInput,
  FormikPhoneInput,
  FormikSelect,
  ProgressIndicator,
  useAlerts,
  User
} from '@reasoncorp/kyber-js';

import {useSsoAppContext} from '../hooks';
import {authenticationApi, userApi, userRoleChangeApi} from '../api';
import * as messages from '../messages';
import {RoleChangeRequestModal, UpdatePasswordModal} from '../components/userProfile';
import {JurisdictionRoleCard, PersonalAddressCard, ProfileCard} from '../components/shared';
import {RoleChangeRequestFormFields, UserProfileFormFields} from '../types/forms';
import {userProfileFormSchema} from '../schema';
import branding from '../branding';
import {UserRoleChangeRequest} from '../types';

const UserProfile = () => {
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {currentUser, setCurrentUser} = useSsoAppContext();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [user, setUser] = useState<User | undefined>(undefined);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false);
  const [isMobileConsentModalOpen, setIsMobileConsentModalOpen] = useState(false);
  const [isRoleChangeRequestModalOpen, setIsRoleChangeRequestModalOpen] = useState(false);

  const shouldDisplayCertificationCard = useMemo(() => {
    return user && user.certifiedUser && branding.certificationLevels.length > 0;
  }, [
    user
  ]);

  const shouldDisplayUserSettingsCard = useMemo(() => {
    return user && branding.userSettingsEnabled;
  }, [
    user
  ]);

  const breadcrumbs = useMemo(() => {
    return [{text: 'User Profile', active: true}];
  }, []);

  const initialValues: UserProfileFormFields = useMemo(() => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    username: user?.username ?? '',
    certifiedUser: user?.certifiedUser ? 'true' : 'false',
    certificationLevel: user?.certificationLevel ?? '',
    certificationNumber: user?.certificationNumber ?? '',
    certificationAttainedDate: user?.certificationAttainedDate ?? '',
    certificationExpiration: user?.certificationExpiration ?? '',
    address: {
      street: user?.address?.street ?? '',
      city: user?.address?.city ?? '',
      state: user?.address?.state ?? '',
      zip: user?.address?.zip ?? ''
    },
    jurisdictionRoles: user?.jurisdictionRoles ?? [],
    userSettings: {
      dailyEmailEnabled: user?.userSettings.dailyEmailEnabled ?? false,
      smsEnabled: user?.userSettings?.smsEnabled ?? false,
      smsPhoneNumber: user?.userSettings?.smsPhoneNumber ?? ''
    }
  }), [
    user
  ]);

  const handleSave = useCallback(async (values: UserProfileFormFields,
                                        formikHelpers: FormikHelpers<UserProfileFormFields>) => {
    try {
      const usernameChanged = currentUser?.username !== values.username;
      const updatedUser = await userApi.updateProfile(values);
      if (usernameChanged) {
        alert(messages.SIGN_IN_WITH_NEW_EMAIL);
        await authenticationApi.signOut();
      } else {
        setCurrentUser(updatedUser);
        setUser(updatedUser);
        showSuccessAlert(messages.USER_SAVE_SUCCESSFUL);
      }
      formikHelpers.resetForm();
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<UserProfileFormFields>};
      if (errorWithType.status === 422 && errorWithType.validationMessages) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      }
      showErrorAlert(messages.USER_SAVE_FAILED);
    } finally {
      setIsMobileConsentModalOpen(false);
      formikHelpers.setSubmitting(false);
    }
  }, [
    currentUser,
    setCurrentUser,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleSubmit = useCallback(async (values: UserProfileFormFields,
                                          formikHelpers: FormikHelpers<UserProfileFormFields>) => {
    const smsEnabledChanged = currentUser?.userSettings.smsEnabled !== values.userSettings.smsEnabled &&
      values.userSettings.smsEnabled;
    if (smsEnabledChanged && !isMobileConsentModalOpen) {
      setIsMobileConsentModalOpen(true);
    } else {
      await handleSave(values, formikHelpers);
    }
  }, [
    currentUser,
    handleSave,
    isMobileConsentModalOpen
  ]);

  const handleRoleChangeRequest = useCallback(async (values: RoleChangeRequestFormFields,
                                                     helpers: FormikHelpers<RoleChangeRequestFormFields>) => {
    setIsRoleChangeRequestModalOpen(false);

    try {
      const updatedUser = await userRoleChangeApi.create(values as UserRoleChangeRequest);
      setUser(updatedUser);

      showSuccessAlert(messages.FORM_SUBMIT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FORM_SUBMIT_FAILURE);
    } finally {
      helpers.resetForm();
    }
  }, [
    showSuccessAlert,
    showErrorAlert
  ]);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const user = await userApi.findCurrent();
        setUser(user);
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_RETRIEVE_USER);
      }
    };

    void loadUser();
  }, [
    showErrorAlert
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Formik initialValues={initialValues}
                  validationSchema={userProfileFormSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}>
            {(formikProps) => (
              <Form autoComplete="off">
                <Row className="mb-3">
                  <Col className="col-6">
                    <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
                  </Col>
                  <Col className="col-6 d-flex justify-content-end">
                    {branding.name === 'MISUITE' && <Button color="primary"
                                                            className="mr-2"
                                                            onClick={() => setIsRoleChangeRequestModalOpen(true)}>
                      Form 4689 Role Change Request
                    </Button>}
                    <Button color="primary"
                            onClick={() => setIsUpdatePasswordModalOpen(true)}>
                      Update Password
                    </Button>
                    <Button color="success"
                            className="ml-2"
                            onClick={formikProps.submitForm}
                            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                      Save
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col className="col-12">
                    <ProfileCard/>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <PersonalAddressCard addressRequired={true}/>
                  </Col>
                </Row>
                {(shouldDisplayCertificationCard || shouldDisplayUserSettingsCard) && <Row className="mb-4">
                  {shouldDisplayCertificationCard && <Col className="col-6">
                    <Card className="h-100">
                      <CardHeader className="bg-secondary text-uppercase text-white">Certification</CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg="6">
                            <FormikInput name="certificationLevel"
                                         labelText="Certification Level"
                                         disabled/>
                          </Col>
                          <Col lg="6">
                            <FormikInput name="certificationNumber"
                                         labelText="Certification Number"
                                         disabled/>
                          </Col>
                          <Col lg="6">
                            <FormikDateInput name="certificationAttainedDate"
                                             labelText="Initial Certification Attained"
                                             disabled/>
                          </Col>
                          <Col lg="6">
                            <FormikDateInput name="certificationExpiration"
                                             labelText="Certification Expiration"
                                             disabled/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>}
                  {shouldDisplayUserSettingsCard &&
                    <Col className={shouldDisplayCertificationCard ? 'col-6' : 'col-12'}>
                      <Card className="">
                        <CardHeader>Communication Preferences</CardHeader>
                        <CardBody>
                          <Row>
                            <Col>
                              <FormikSelect name="userSettings.dailyEmailEnabled"
                                            labelText="Email Preference">
                                <option value="false">(Default) Immediate: send individual email notification as they happen</option>
                                <option value="true">Digest: Send an email digest once a day</option>
                              </FormikSelect>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormikCheckboxGroup inline
                                                   checkboxes={[{
                                                     name: 'userSettings.smsEnabled',
                                                     labelText: `Enable updates via text messaging.`
                                                   }]}/>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormikPhoneInput name="userSettings.smsPhoneNumber"
                                                placeholder="SMS Phone Number"
                                                disableFloatingLabel={true}
                                                disabled={!formikProps.values.userSettings.smsEnabled}
                                                labelText="Phone number to receive SMS message"/>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>}
                </Row>}
                <Row>
                  {formikProps.values.jurisdictionRoles.length !== 0 && <Col className="col-12">
                    <JurisdictionRoleCard className="mb-4"
                                          adminView={false}
                                          defaultEmail={user?.username ?? ''}
                                          jurisdictionRoles={formikProps.values.jurisdictionRoles}
                                          setJurisdictionRoles={(newJurisdictionRoles) => formikProps.setFieldValue('jurisdictionRoles', newJurisdictionRoles)}
                                          disabled={formikProps.isSubmitting}/>
                  </Col>}
                </Row>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button color="success"
                            onClick={formikProps.submitForm}
                            disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                      Save
                    </Button>
                  </Col>
                </Row>
                <ConfirmationModal isOpen={isMobileConsentModalOpen}
                                   size="lg"
                                   title="Enable Mobile Notifications"
                                   confirmButtonText="Enable"
                                   confirmCallback={formikProps.submitForm}
                                   cancelButtonText="Cancel"
                                   cancelCallback={() => setIsMobileConsentModalOpen(false)}>
                  <p>
                    I would like to use the mobile phone number <strong className="text-primary">{formikProps.values.userSettings.smsPhoneNumber}</strong> to receive SMS notifications about important MiSuite updates.
                  </p>
                  <p className="small text-muted text-justify mt-3">
                    By enabling mobile notifications, I agree to receive text messages from MiSuite. Standard message and data rates apply.
                  </p>
                </ConfirmationModal>
              </Form>
            )}
          </Formik>

          <UpdatePasswordModal isOpen={isUpdatePasswordModalOpen}
                               onCancel={() => setIsUpdatePasswordModalOpen(false)}/>
          <RoleChangeRequestModal isOpen={isRoleChangeRequestModalOpen}
                                  user={user}
                                  onSubmit={handleRoleChangeRequest}
                                  onCancel={() => setIsRoleChangeRequestModalOpen(false)}/>
        </>
        }
      </Container>
    );
  }
};

export default UserProfile;