import {memo, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {AppBar, SOMLogo, SsoNotificationButton, User, UserProfile} from '@reasoncorp/kyber-js';

import branding from '../branding';
import config from '../config';
import {ssoApi} from '../api';

type Props = {
  currentUser?: User | null
  isAdmin: boolean
}

const SsoAppBar = ({
                     currentUser,
                     isAdmin
                   }: Props) => {
  const navigate = useNavigate();
  const handleProfileClick = useCallback(() => navigate('/user-profile'), [navigate]);
  const handleNotificationsClick = useCallback(() => navigate('/notifications'), [navigate]);
  const handleSignOut = useCallback(async () => await ssoApi.signOut(), []);

  const renderNotificationButton = useMemo(() => {
    return branding.name === 'MISUITE' && currentUser ? () =>
      <SsoNotificationButton onNotificationsClick={handleNotificationsClick}
                             ssoApi={ssoApi}/> : undefined;
  }, [
    handleNotificationsClick,
    currentUser
  ]);

  const renderUserProfile = useMemo(() => {
    return currentUser ? () =>
      <UserProfile handleProfileClick={handleProfileClick}
                   handleSignOut={handleSignOut}/> : undefined;
  }, [
    handleProfileClick,
    handleSignOut,
    currentUser
  ]);

  const appBarProps = useMemo(() => {
    return {
      brandLink: `${config.sso.webUrl}/dashboard`,
      brandImage: SOMLogo,
      brandImageAlt: branding.title,
      appName: branding.title,
      organizationName: branding.organizationName,
      environmentName: config.envName !== 'prod' ? config.envName : undefined,
      routes: [
        {name: 'Notifications', to: '/notifications', hide: branding.name !== 'MISUITE' || !currentUser},
        {name: 'Users', to: '/users', hide: !isAdmin},
        {name: 'Role Change Requests', to: '/role-changes', hide: !isAdmin || branding.name !== 'MISUITE'}
      ],
      renderNotificationButton,
      renderUserProfile
    };
  }, [
    currentUser,
    isAdmin,
    renderNotificationButton,
    renderUserProfile
  ]);

  return <AppBar {...appBarProps}/>;
};

export default memo(SsoAppBar);